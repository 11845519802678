import styles from "./FilteredFoodList.module.css";
import { CiSearch } from "react-icons/ci";
import { GoArrowUpLeft } from "react-icons/go";
import Highlighter from "react-highlight-words";
const FilteredFoodList = ({
  filteredData,
  searchTerm,
  setSearchText,
  setSearchedData,
}) => {
  return filteredData?.map((item) => {
    return (
      <>
        <div
          key={item._id}
          className={styles.container}
          onClick={(e) => {
            setSearchText(item.name);
          }}
        >
          <div className={styles.left}>
            <CiSearch size={22} />
            {/* Use Highlighter component to highlight matching text */}
            <span className={styles.hello} key={item.name}>
              <Highlighter
                highlightClassName={styles.highlight}
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={item.name}
              />
            </span>
          </div>
          <GoArrowUpLeft size={22} />
        </div>
        <hr className={styles.hr} />
      </>
    );
  });
};
export default FilteredFoodList;
