import React from "react";
import styles from "./ConfirmItem.module.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useCartTotals from "../../../hooks/useCartTotal";
import { motion } from "framer-motion";

const ConfirmItem = () => {
  const itemsInCart = useSelector((state) => state.cart.items);
  const { totalItemPrice, totalQuantity } = useCartTotals(itemsInCart);
  return (
    <div className={styles.bottomdiv}>
      <div className={styles.orderInfo}>
        <div className={styles.itemadded}>{totalQuantity} items added</div>
        <div className={styles.totalbill}>
          Total Bill <span className={`${styles.price} fw-bold`}>₹ </span>
          <span className={styles.price}>{totalItemPrice}</span>
        </div>
      </div>
      <Link to={"/order"}>
        <motion.button className={styles.button} whileTap={{ scale: 0.9 }}>
          Next
        </motion.button>
      </Link>
    </div>
  );
};

export default ConfirmItem;
