import API from "./API";

export const getRestaurants = async (toast) => {
  try {
    const data = await API.get("restaurants");
    return data.data;
  } catch (error) {
    toast.showErrorToast(error.message);
  }
};
export const getRestaurantById = async (id) => {
  try {
    const response = await API.get(`restaurants/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getRating = async (id, toast) => {
  try {
    const response = await API.get("reviews");
    const restaurant_reviews = response.data.filter(
      (item) => item.restaurant_id === id
    );
    if (restaurant_reviews.length === 0) {
      return 0;
    }
    const totalRating = restaurant_reviews.reduce(
      (accumulator, item) => accumulator + item.rating,
      0
    );
    const avgRating = totalRating / restaurant_reviews.length;
    return avgRating;
  } catch (error) {
    toast.showErrorToast(error.message);
  }
};
export const sendNotification = async (data) => {
  try {
    const response = await API.post("notification-user/send", data);
    return response;
  } catch (error) {
    console.log(error);
  }
};
