import React, { useEffect } from "react";
import styles from "./SearchBar.module.css";
import { CiSearch } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { MdArrowBackIos } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setShowSearchBar } from "../../../../store/searchSlice";
import FilteredFoodList from "./FilteredFoodList";
import { DebounceInput } from "react-debounce-input";
import FoodList from "../../../FoodItemList/FoodList";
import fuzzysort from "fuzzysort";
import { motion } from "framer-motion";
const SearchBar = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState(null);
  const dispatch = useDispatch();
  const restaurantId = localStorage.getItem("restaurantId");
  const foodItems = useSelector((state) => state.foods.foodItems);
  const { foodCategorySlice } = useSelector((state) => state.foodCategory);
  const clearField = () => {
    setSearchText("");
    setSearchedData(null);
  };

  // Update filtered food data whenever foodData or searchTerm changes
  const search = (e) => {
    const text = e.target.value.trim(); // Trim whitespace from input
    setSearchText(text); // Set state with the trimmed text
  };
  useEffect(() => {
    const foodData = foodItems.data.filter(
      (item) => restaurantId === item.restaurant_id
    );
    if (searchText.length === 0) {
      setSearchedData(null);
    } else {
      const filteredData = foodData.filter((item) => {
        const searchTextLower = searchText.toLowerCase().replace(/\s/g, ""); // Remove spaces from search text
        const itemText = item.name.toLowerCase().replace(/\s/g, ""); // Remove spaces from item name
        const result = fuzzysort.single(searchTextLower, itemText);
        return result != null; // Return true if a match is found
      });
      setSearchedData(filteredData);
    }
  }, [searchText]);

  return (
    <>
      <div className={styles.container}>
        <div
          className="d-flex align-items-center px-3"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            height: "100px",
            position: "fixed",
            top: 0,
            // zIndex: 1,
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <MdArrowBackIos
            className={styles.backIcon}
            onClick={() => dispatch(setShowSearchBar(false))}
          />
          <div className={styles.inputContainer}>
            <CiSearch className={styles.searchIcon} />
            <DebounceInput
              type="text"
              minLength={1}
              value={searchText}
              placeholder="Search food items..."
              debounceTimeout={300}
              className={styles.input}
              onChange={(e) => {
                search(e);
              }}
            />
            <RxCross2
              className={styles.crossIcon}
              onClick={() => clearField()}
            />
          </div>
        </div>

        <div className={styles.result}>
          {searchedData && searchedData.length > 0 ? (
            <>
              <FilteredFoodList
                filteredData={searchedData}
                searchTerm={searchText}
                setSearchText={setSearchText}
                setSearchedData={setSearchedData}
              />
              <FoodList
                foodData={searchedData}
                foodCategorySlice={foodCategorySlice?.data}
                search={true}
              />
            </>
          ) : (
            searchText && (
              <p className={styles.noResult}>
                No items found based on your search.
              </p>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default SearchBar;
