import React, { useEffect } from "react";
import { fetchFoodCategory } from "../../store/foodCategorySlice";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ExpandedMenu.module.css";
import { motion } from "framer-motion";

const ExpandedMenu = ({ showExpandedMenu, setShowExpandedMenu }) => {
  const dispatch = useDispatch();
  const { foodCategorySlice } = useSelector((state) => state.foodCategory);
  const { foodItems, status } = useSelector((state) => state.foods);
  const foodData = foodItems?.data ?? [];

  useEffect(() => {
    dispatch(fetchFoodCategory());
  }, []);
  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{
        height: showExpandedMenu ? "auto" : 0,
        opacity: showExpandedMenu ? 1 : 0,
      }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className={`${styles.outer}`}
    >
      {foodCategorySlice?.data?.map(
        (cat) => {
          const hasSubCategoriesWithItems = cat?.subCategory?.some((subCat) =>
            foodData?.some((item) => item?.category?._id === subCat?._id)
          );
          if (hasSubCategoriesWithItems) {
            return (
              <>
                <div
                  key={cat._id}
                  className="bg-white fw-bold fs-4"
                  onClick={() => setShowExpandedMenu(false)}
                  //   style={{ color: "#1c93ce" }}
                >
                  <a href={`#${cat._id}`} className={styles.link}>
                    {cat?.name}
                  </a>
                </div>
                {cat?.subCategory?.map((subCat) => {
                  if (
                    foodData?.some(
                      (item) => item?.category?._id === subCat?._id
                    )
                  ) {
                    return (
                      <div
                        key={subCat._id}
                        className="bg-white fw-normal fs-5"
                        onClick={() => setShowExpandedMenu(false)}
                        //   style={{ color: "#1c93ce" }}
                      >
                        <a href={`#${subCat?._id}`} className={styles.link}>
                          {subCat?.name}
                        </a>
                      </div>
                    );
                  }
                })}
              </>
            );
          }
        }

        // (

        //   <>

        //     <div
        //       key={cat._id}
        //       className="bg-white fw-normal fs-5"
        //       onClick={() => setShowExpandedMenu(false)}
        //       //   style={{ color: "#1c93ce" }}
        //     >
        //       <a href={`#${cat._id}`} className={styles.link}>
        //         {cat?.name}
        //       </a>
        //     </div>
        //     {cat?.subCategory?.map((subCat) => (
        //       <>
        //         <div
        //           key={subCat._id}
        //           className="bg-white fw-normal fs-5"
        //           onClick={() => setShowExpandedMenu(false)}
        //           //   style={{ color: "#1c93ce" }}
        //         >
        //           <a href={`#${subCat?._id}`} className={styles.link}>
        //             {subCat?.name}
        //           </a>
        //         </div>
        //       </>
        //     ))}
        //   </>
        // )
      )}
    </motion.div>
  );
};

export default ExpandedMenu;
