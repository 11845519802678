import React from "react";
import Header from "../../components/Header/Header";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userIsLogged } from "../../services/user";
import useToast from "../../utils/toast/useToast";
const Layout = (props) => {
  const toast = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    userIsLogged(navigate, toast);
  }, []);

  return (
    <>
      <Header />
      <div
        style={{
          backgroundColor: "#E4E4E4",
          // minHeight: "87vh",
          overflowX: "hidden",
        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default Layout;
