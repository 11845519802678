import styles from "./UrlError.module.css";
import error404 from "../../../assets/error404.jpg";

const UrlError = () => {
  // localStorage.removeItem("restaurantId");
  // localStorage.removeItem("tableNumber");
  return (
    <div className={styles.error}>
      <img src={error404} alt="" />
      <h1 className="fw-bold">RESTAURANT NOT FOUND</h1>
    </div>
  );
};

export default UrlError;
