import React from "react";
import { GrFormNext } from "react-icons/gr";
const RightButton = ({ next }) => {
  return (
    <div
      className="position-absolute"
      style={{
        top: "45%",
        right: "7px",
        backgroundColor: "var(--fourth-color)",
        borderRadius: "50%",
      }}
      onClick={(e) => {
        e.stopPropagation();
        next();
      }}
    >
      <GrFormNext size={25} color="white" />
    </div>
  );
};

export default RightButton;
