import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchFoodItems = createAsyncThunk(
  "foods/fetchFoodItems",
  async () => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL +
        "food-items/?restaurant_id" +
        `${localStorage.getItem("restaurantId")}`
    );
    return response.data;
  }
);

const foodSlice = createSlice({
  name: "foods",
  initialState: {
    foodItems: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFoodItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFoodItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.foodItems = action.payload; // Update foodItems with the fetched data
      })
      .addCase(fetchFoodItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default foodSlice.reducer;
