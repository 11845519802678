import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  fromContainer: {
    width: "50%",
    textAlign: "left",
  },
  toContainer: {
    width: "50%",
    textAlign: "right",
  },
  billFrom: {
    marginTop: 20,
    fontSize: 15,
    paddingBottom: 3,
    fontFamily: "Helvetica-Bold",

    textTransform: "uppercase",
    fontWeight: "bolder",
  },
  billTo: {
    marginTop: 20,
    fontSize: 15,
    paddingBottom: 3,
    fontFamily: "Helvetica-Bold",

    textTransform: "uppercase",
    // fontFamily: "Helvetica-Oblique",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const BillTo = ({ invoice }) => (
  <View style={styles.flex}>
    <View style={styles.fromContainer}>
      <Text style={styles.billFrom}>Invoice From:</Text>
      <Text>{invoice.restaurant}</Text>
      <Text>{invoice.res_address}</Text>
      <Text>{invoice.res_contact}</Text>
    </View>
    <View style={styles.toContainer}>
      <Text style={styles.billTo}>Invoice To:</Text>
      <Text>{invoice.customer ? invoice.customer : "Guest User"}</Text>
      <Text>{invoice.address}</Text>
      <Text>{invoice.phone}</Text>
    </View>
  </View>
);

export default BillTo;
