import React from "react";
import { ShimmerSimpleGallery, ShimmerTable } from "react-shimmer-effects";

const Shimmer = () => {
  return (
    <>
      {/* <ShimmerTable row={0} col={4} /> */}
      <ShimmerSimpleGallery card imageHeight={100} row={5} col={1} />
    </>
  );
};

export default Shimmer;
