import React from "react";
import CartItem from "./CartItem";
import { motion } from "framer-motion";

const CartItems = ({ cartItemList }) => {
  return (
    <ul className="list-group">
      {cartItemList &&
        cartItemList.length > 0 &&
        cartItemList.map((item, index) => (
          <div key={item._id}>
            <CartItem key={item._id} item={item} />
          </div>
        ))}
    </ul>
  );
};

export default CartItems;
