import { toast } from "react-toastify";

const useToast = () => {
  const showToast = (message, options = {}) => {
    toast.dismiss();
    toast(
      message,
      {
        ...options,
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
      }
    );
  };

  const showInfoToast = (message) => {
    showToast(message, { type: "info" });
  };

  const showSuccessToast = (message) => {
    showToast(message, { type: "success" });
  };

  const showWarningToast = (message) => {
    showToast(message, { type: "warning" });
  };

  const showErrorToast = (message) => {
    showToast(message, { type: "error" });
  };

  const showDefaultToast = (message) => {
    showToast(message);
  };

  return {
    showInfoToast,
    showSuccessToast,
    showWarningToast,
    showErrorToast,
    showDefaultToast,
  };
};

export default useToast;
