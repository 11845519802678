import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userName: "",
    phoneNumber: "",
    email: "",
  },
  reducers: {
    updateUser: (state, action) => {
      const { userName, phoneNumber, email } = action.payload;
      state.userName = userName;
      state.phoneNumber = phoneNumber;
      state.email = email;
    },
  },
});
export const { updateUser } = userSlice.actions;
export default userSlice.reducer;
