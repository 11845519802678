import axios from "axios";
import Cookies from "js-cookie"; 

const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const API = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

API.interceptors.request.use(async (config) => {
  const token = Cookies.get("token"); 
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers["Content-Type"] = "application/json";
  config.headers.Accept = "application/json";
  return config;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      throw error;
    }
    throw error;
  }
);

export default API;
