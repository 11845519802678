import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const CommonModal = ({
  visible = true,
  modalStyle,
  heading,
  headingStyle,
  headingCenter = false,
  closeButton = false,
  content,
  contentStyle,
  children,
  btn1Text = "Close",
  btn1Variant = "secondary",
  btn1OnClick,
  btn1Style,
  btn2Text,
  btn2Variant = "secondary",
  btn2OnClick,
  btn2Style,
  titleStyle,
  FooterStyle,
  centered = false,
  handleClose,
  backdrop,
  btn2Class,
}) => {
  // Define header style based on headingCenter prop
  const headerStyle = headingCenter
    ? {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        ...headingStyle, // Apply additional heading style
      }
    : {
        ...headingStyle, // Apply heading style only
      };

  return (
    <Modal
      show={visible}
      onHide={handleClose}
      centered={centered}
      keyboard={false}
      style={modalStyle}
    >
      {heading && (
        <Modal.Header closeButton={closeButton} style={headerStyle}>
          <Modal.Title style={{ ...titleStyle }}>{heading}</Modal.Title>
        </Modal.Header>
      )}
      {(content || children) && (
        <Modal.Body style={contentStyle}>{content || children}</Modal.Body>
      )}
      <Modal.Footer style={{ ...FooterStyle }}>
        {btn1Text && (
          <Button
            variant={btn1Variant}
            onClick={btn1OnClick || handleClose}
            style={{
              backgroundColor: "#8A8A8A",
              fontWeight: 700,
              border: "none",
              ...btn1Style,
            }}
          >
            {btn1Text}
          </Button>
        )}
        {btn2Text && (
          <Button
            variant={btn2Variant}
            onClick={btn2OnClick}
            style={{
              backgroundColor: "rgba(195,0,26,255)",
              fontWeight: 700,
              border: "none",
              ...btn2Style,
            }}
            className={btn2Class}
          >
            {btn2Text}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CommonModal;

// Example use

// {/ <CommonModal headingCenter={true} handleClose={handleClose} visible={show} titleStyle={{fontWeight:700}} btn2OnClick={()=>alert("onclick button 2")} closeButton={true}  btn1Text={"Cancel"} heading={"Log Out"} btn2Text={"Login"}  >Aman this ide</CommonModal> /}
