import { Button, Modal } from "react-bootstrap";

const TaxDisplayPopup = ({ amount, showTaxes, setShowTaxes, taxes }) => {
  const handleClose = () => setShowTaxes(false);
  // const taxes = JSON.parse(localStorage.getItem("taxes"));
  const calculateTaxes = () => {
    const cgst = (amount * taxes?.cgst) / 100;
    const sgst = (amount * taxes?.sgst) / 100;
    return { cgst, sgst };
  };

  const { cgst, sgst } = calculateTaxes(taxes);
  return (
    <div>
      <Modal show={showTaxes} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Taxes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Item Amount: ₹{amount}</p>
          <p>
            CGST: ₹{cgst.toFixed(2)} ({taxes?.cgst}%)
          </p>
          <p>
            SGST: ₹{sgst.toFixed(2)} ({taxes?.sgst}%)
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TaxDisplayPopup;
