import { createSlice } from "@reduxjs/toolkit";
// Create a separate function to handle scroll event
const handleScrollEvent = () => {
  return (dispatch) => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 290) {
        dispatch(scrollSlice.actions.show());
      } else {
        dispatch(scrollSlice.actions.hide());
      }
    });

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  };
};
const scrollSlice = createSlice({
  name: "scroll",
  initialState: {
    show: false,
  },
  reducers: {
    show: (state) => {
      state.show = true;
    },
    hide: (state) => {
      state.show = false;
    },
  },
});
export const { show, hide } = scrollSlice.actions;
export const handleScroll = handleScrollEvent;
export default scrollSlice.reducer;
