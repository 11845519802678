import React from "react";
import styles from "./NetworkErrPage.module.css";
import { IoChevronBack, IoSearch } from "react-icons/io5";
import whoops from "../../../assets/whoops.png";
import { useNetworkStatus } from "../../../hooks/NetworkStatus";

function NetworkErrPage() {
  const isOnline = useNetworkStatus();

  const handleReload = () => {
    if (isOnline) {
      window.location.reload();
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.left}>
          {/* <IoChevronBack size={25} /> */}

          <span className="hotelName mx-3">Spicy Hut </span>
        </div>

        {/* <IoSearch /> */}
      </div>
      <center className={styles.outer}>
        <img src={whoops} className={styles.Img} alt="Network Error..." />

        <div className={styles.ErrorMsg}>
          <h3 className={styles.whoopsHeading}>Whoops! </h3>
          <p className={styles.msg}>
            No Internet Connections Founds Please check your Internet Settings.
          </p>
        </div>
      </center>
      <div className={styles.footer}>
        <button className={styles.button} type="button" onClick={handleReload}>
          Reload
        </button>
      </div>
    </div>
  );
}

export default NetworkErrPage;
