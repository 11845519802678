import { useEffect, useState } from "react";
import { quantityPrice } from "../constants";

const useCartTotals = (itemsInCart) => {
  const [totals, setTotals] = useState({
    totalItemPrice: 0,
    total: 0,
    cgst: 0,
    sgst: 0,
    taxes: 0,
    totalQuantity: 0,
  });

  useEffect(() => {
    if (!itemsInCart || !Array.isArray(itemsInCart)) return; // Defensive check for itemsInCart

    const totalItemPrice = itemsInCart.reduce((accumulator, item) => {
      return (
        accumulator +
        (item && item.total ? item.total : item.basePrice) * item.quantity
      );
    }, 0);

    const totalQuantity = itemsInCart.reduce(
      (total, item) => total + parseInt(item.quantity),
      0
    );
    const storedTaxesData = JSON.parse(localStorage.getItem("taxes"));
    const cgst = storedTaxesData.cgst ? storedTaxesData?.cgst : 0;
    const sgst = storedTaxesData.sgst ? storedTaxesData?.sgst : 0;

    const taxes = ((cgst + sgst) / 100) * totalItemPrice;
    const total = (totalItemPrice + taxes).toFixed(2);
    const totalNumber = parseFloat(total);

    setTotals({
      totalItemPrice: totalItemPrice.toFixed(2),
      total: totalNumber,
      taxes,
      totalQuantity,
    });
  }, [itemsInCart]);
  return totals;
};

export default useCartTotals;
