export const compareAddOns = (addOns1, addOns2) => {
    if (!addOns1 || !addOns2) return addOns1 === addOns2;
  
    if (addOns1.length !== addOns2.length) return false;
  
    // Extract and sort _id properties for comparison
    const ids1 = addOns1.map(addOn => addOn._id).sort();
    const ids2 = addOns2.map(addOn => addOn._id).sort();
  
    // Compare sorted arrays of _id
    return ids1.every((id, index) => id === ids2[index]);
  };