import React from "react";
import { Button } from "../elements/Button";
import { repeatItem, updateItem } from "../../store/cartSlice";
import { useDispatch } from "react-redux";
import styles from "./RepeatPrevious.module.css"; // Import CSS Module file
import { motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
const RepeatPrevious = ({
  setShowRepeatPopup,
  setShowCustomizePage,
  item,
  setSameItem,
  setNewItem,
}) => {
  const dispatch = useDispatch();
  const handleUpdateCart = () => {
    if (item.instructions == "") {
      dispatch(updateItem({ ...item }));
    } else {
      dispatch(repeatItem({ ...item }));
    }
    setShowRepeatPopup(false);
  };
  const handleOutsideClick = () => {
    setShowRepeatPopup(false);
  };
  return (
    <div className={styles.container} onClick={() => handleOutsideClick()}>
      <motion.div
        className={styles.popup}
        initial={{ translateY: "100%" }}
        animate={{ translateY: "0%" }}
        transition={{ duration: 0.4 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-100 d-flex justify-content-between">
          <h5 className="fw-bold">{item.name}</h5>
          <RxCross2
            size={22}
            color="black"
            onClick={() => setShowRepeatPopup(false)}
          />
        </div>
        <h6 className="fw-bold"> ₹ {item.basePrice}</h6>
        <hr />

        <div className={styles["button-wrapper"]}>
          <div
            onClick={() => {
              setShowCustomizePage(true);
              setShowRepeatPopup(false);
              setNewItem(false);
              setSameItem(false);
            }}
          >
            <Button
              value="I'll Choose"
              style={{
                width: "130px",
                height: "35px",
                borderRadius: "10px",
                marginBottom: "15px",
                color: "#FA4A0C",
                border: "2px solid #FA4A0C",
                backgroundColor: "#F9D9CD",
              }}
            />
          </div>

          <div onClick={handleUpdateCart}>
            <Button
              value="Repeat Last"
              style={{
                width: "130px",
                height: "35px",
                borderRadius: "10px",
                marginBottom: "15px",
              }}
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default RepeatPrevious;
