import styles from "./OrderSuccessful.module.css";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import confettiImg from "../../../assets/confetti.png";
import { motion } from "framer-motion";

const OrderSuccessCard = ({ isVisible, onClose, orderId, tableNo }) => {
  setTimeout(() => {
    onClose();
  }, 5000);

  return (
    <>
      <div
        className={`${styles.modalBackdrop} ${isVisible ? styles.visible : ""}`}
        onClick={onClose}
      >
        {" "}
      </div>
      <motion.div
        className={`${styles.card} ${isVisible ? styles.visible : ""}`}
        onClick={(e) => e.stopPropagation()}
        initial={{ scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4 }}
      >
        <div className={styles.icon}>
          <IoCheckmarkCircleOutline size={70} />
        </div>
        <img src={confettiImg} alt="" className={styles.confetti} />
        <div className={styles.ordertext}>Order Successful</div>
        <div className={styles.thanktext}>Thank you so much for your order</div>
        <div className={styles.orderid}>Order Number: {orderId}</div>
        <div className={styles.tableno}>{tableNo}</div>
      </motion.div>
    </>
  );
};

export default OrderSuccessCard;

/* To Use This Modals

<OnSuccessfulCard isVisible={true} onClose={() => {}} orderId="12345" tableNo={5} />

*/
