import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import Lobster from "../../assets/fonts/Lobster-Regular.ttf";
import Inter from "../../assets/fonts/Inter-wght.ttf";
Font.register({
  family: "Lobster,sans-serif",
  src: Lobster,
});
Font.register({
  family: "Inter",
  src: Inter,
});
const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 6,
  },
  reportTitle: {
    color: "black",
    letterSpacing: 4,
    fontSize: 35,
    textAlign: "center",
    fontFamily: "Lobster,sans-serif",
  },
  reportDate: {
    color: "black",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Inter",
  },
});

function extractDate(orderString) {
  const datePattern = /\d{8}/;
  const match = orderString.match(datePattern);

  if (match) {
    const dateString = match[0];
    return `${dateString.slice(6)}-${dateString.slice(4, 6)}-${dateString.slice(
      0,
      4
    )}`;
  } else {
    return null;
  }
}

// const orderNumber = "ORD#: 20240902-0007";
// const extractedDate = extractDate(orderNumber);

// console.log(extractedDate); // Output: 02-09-2024

const InvoiceTitle = ({ title, invoice }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{title}</Text>
    <Text style={styles.reportDate}> {extractDate(invoice?.invoice_no)}</Text>
  </View>
);

export default InvoiceTitle;
