import React from "react";
import styles from "./PrevOrderCard.module.css";
import { LuCalendarCheck2 } from "react-icons/lu";
import { MdOutlineAccessTime } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStatusColor, orderStatus, orderType } from "../../constants";
import { motion } from "framer-motion";
import dinein from "../../assets/dinein.png";
import pickup from "../../assets/food-pick-up.png";
import delivery from "../../assets/food-delivery.png";
const PrevOrderCard = ({ orders }) => {
  // const prevOrders = useSelector((state) => state.orderHistory.ordersHistory);
  const prevOrders = orders;
  const navigate = useNavigate();
  const orderDetails = (item) => {
    navigate(`/prevorder/${item._id}`);
  };

  console.log({ prevOrders });

  return prevOrders.length === 0 ? (
    <div className="d-flex justify-content-center align-items-center fs-5 p-4">
      No Order History
    </div>
  ) : (
    prevOrders?.map((order, index) => (
      <div
        className={styles.outer}
        key={order.orderId || index}
        onClick={() => orderDetails(order)}
        // initial={{ x: "100%", opacity: 0 }}
        // animate={{ x: 0, opacity: 1 }}
        // transition={{
        //   ease: [0.17, 0.67, 0.83, 0.67],
        //   duration: 0.3,
        //   delay: index * 0.3,
        // }}
      >
        <div className="d-flex justify-content-between align-items-center text-capitalize">
          <div className={styles.ordername}>
            {order &&
              order.items &&
              order.items.length &&
              order.items.map(
                (itemOrder, index) =>
                  itemOrder.name +
                  `${index !== order.items.length - 1 ? ", " : ""}`
              )}{" "}
          </div>
          <div
            className="rounded px-2 py-1"
            style={{
              // border: "1px solid #7a1b1b",
              backgroundColor: "#98a12259",
            }}
          >
            <img
              src={
                order && order.orderType == orderType.PickUp
                  ? pickup
                  : order.orderType == orderType.dineIn
                  ? dinein
                  : delivery
              }
              alt=""
              height={22}
              width={25}
            />
          </div>
        </div>
        <hr className={styles.hr} />

        <div className={styles.orderdetails}>
          <h4 style={{ color: "var(--primary-color)" }}>₹ {order?.total}</h4>

          <div style={{ display: "flex", gap: "5px" }}>
            {Boolean(order?.payment?.due) && (
              <h6
                style={{
                  color: "white",
                  backgroundColor: "#FFB72B",
                }}
              >
                Due
              </h6>
            )}
            <h6
              style={{
                color: "white",
                backgroundColor: `${getStatusColor(
                  orderStatus[order?.orderStatus]
                )}`,
              }}
            >
              {orderStatus[order?.orderStatus]}
            </h6>
          </div>
        </div>
        <div className={styles.orderTable}>
          <div>
            <span className="fw-bold">Order No:</span> {order?.orderNo}
          </div>
          <div style={{ color: "var(--second-color)" }}>
            {" "}
            {order?.tableName ? order?.tableName : order?.tableNumber}
          </div>{" "}
        </div>
        <div className={styles.transaction}>
          <div className={styles.datetime}>
            <span>
              <LuCalendarCheck2
                size={16}
                color="var(--primary-color)"
                className="me-1"
              />{" "}
              {order?.orderDate}
            </span>
            <span>
              <MdOutlineAccessTime
                size={16}
                color="var(--primary-color)"
                className="me-1"
              />{" "}
              {order?.orderTime}
            </span>
          </div>
        </div>
      </div>
    ))
  );
};

export default PrevOrderCard;
