import React, { useEffect, useRef, useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { generate } from "../store/otpSlice";
import { motion } from "framer-motion";
import greenTick from "../assets/green-tickk.svg";
import { Button } from "../components/elements/Button";
import styles from "../styles/OtpVerify.module.css";
import otpImg from "../assets/otp.svg";
import useToast from "../utils/toast/useToast";
import {
  otpVerifying,
  generateToken,
  getUserData,
  createNotificationUser,
} from "../services/user";
import { useLocation } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

const OtpVerify = ({ setShowLogin, loginState }) => {
  const location = useLocation();
  const state = location.state;

  const contact = useSelector((state) => state.user.phoneNumber);
  const correctOtp = useSelector((state) => state.otp.otp);
  const notificationtoken = localStorage.getItem("notificationtoken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  // Timer to resend OTP
  const [timing, setTiming] = useState(59);
  const [activeResend, setActiveResend] = useState(false);
  const [showVerified, setShowVerified] = useState(false);
  const [showBlink, setShowBlink] = useState(false);
  const [showRedBlink, setShowRedBlink] = useState(false);

  // Refs for OTP input fields
  const otp1 = useRef(null);
  const otp2 = useRef(null);
  const otp3 = useRef(null);
  const otp4 = useRef(null);

  const handleResendOtp = () => {
    toast.showSuccessToast("OTP Sent");
    dispatch(generate());
    setActiveResend(false);
    setTiming(59);
  };
  async function fetchNotification(userData) {
    const res = await createNotificationUser({
      userId: userData._id,
      userName: userData.userName,
      notification_token: notificationtoken,
    });
  }

  useEffect(() => {
    let timerId = setInterval(() => {
      setTiming((prevTiming) => {
        if (prevTiming === 0) {
          clearInterval(timerId);
          setActiveResend(true);
          return 0;
        } else {
          return prevTiming - 1;
        }
      });
    }, 1000);
    return () => clearInterval(timerId);
  }, [timing]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const otpValue =
      otp1.current.value +
      otp2.current.value +
      otp3.current.value +
      otp4.current.value;

    if (otpValue === "") {
      toast.showErrorToast("OTP Required");
      return;
    }

    if (parseInt(otpValue) === correctOtp) {
      try {
        await otpVerifying(contact, toast);
        const response = await generateToken({ phoneNumber: contact }, toast);
        const data = await getUserData(toast);
        fetchNotification(data);
        if (response.status === 201) {
          const data = await getUserData(toast);
          localStorage.setItem("userDetails", JSON.stringify(data));
          localStorage.setItem("role", "user");
          if (loginState) {
            if (loginState?.from === "/order") {
              navigate("/order?login=true", {
                replace: true,
              });
              console.log("if");
            } else {
              navigate(-1, { replace: true });
              setShowLogin(null);
              console.log("else");
            }
          }

          // fetchNotification(data);
        }
      } catch (error) {
        console.log(error);
      }

      setShowBlink(true);

      setTimeout(() => {
        setShowBlink(false);
        setShowVerified(true);
      }, 500);
      if (loginState) {
        setTimeout(() => {
         setShowVerified(false)
         setShowLogin(null);
        }, 3000);
      } else {
        setTimeout(() => {
          navigate(-1, { replace: true });
        }, 3000);
      }
    } else {
      toast.showErrorToast("Invalid OTP");
      setShowRedBlink(true);
      setTimeout(() => {
        setShowRedBlink(false);
      }, 500);
      setTimeout(() => {
        otp1.current.focus();
      }, 500);
    }
    console.log("Entered OTP:", otpValue);
    otp1.current.value = "";
    otp2.current.value = "";
    otp3.current.value = "";
    otp4.current.value = "";
  };

  const handleOtpChange = (event, ref) => {
    const maxLength = parseInt(ref.current.getAttribute("maxlength"), 10);
    if (event.target.value.length >= maxLength) {
      const nextInput = event.target.nextElementSibling;
      if (nextInput) {
        nextInput.focus();
      } else {
        event.target.blur();
      }
    }
  };

  const handleBackspace = (event, ref) => {
    if (event.key === "Backspace" && event.target.value.length === 0) {
      const prevInput = event.target.previousElementSibling;
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  return (
    <div className={`${styles.overlay}`} onClick={() => setShowLogin(null)}>
      <motion.div
        initial={{ translateY: "100%" }}
        animate={{ translateY: "0%" }}
        transition={{ duration: 0.4 }}
        className={`d-flex justify-content-center align-item-center ${styles.modalDialog}`}
        style={{
          position: "absolute",
          width: "100%",
          height: "87%",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="position-absolute">
          <Link to={"/home"} replace={true} className={styles.verifytext}>
            <RxCross2
              size={40}
              onClick={() => setShowLogin(null)}
              className={styles.exit}
            />{" "}
          </Link>
          <div className={`${styles.verifymain} text-center`}>
            <img src={otpImg} height={"200px"} width={"200px"} alt="" />
            <div className={styles.text}>
              Please enter verification code sent to your mobile number{" "}
            </div>
            <div className={styles.sent}>sent to +91 {contact}</div>
            <div className={styles.otp}></div>
            <form onSubmit={handleSubmit}>
              <div className={styles.otp}>
                <input
                  name="otp1"
                  type="number"
                  autoComplete="off"
                  ref={otp1}
                  className={`${styles.otpInput} ${
                    showBlink && styles.success
                  } ${showRedBlink && styles.failure} `}
                  tabIndex="1"
                  maxLength="1"
                  onChange={(e) => handleOtpChange(e, otp1)}
                  onKeyDown={(e) => handleBackspace(e, otp1)}
                />
                <input
                  name="otp2"
                  type="number"
                  autoComplete="off"
                  ref={otp2}
                  className={`${styles.otpInput} ${
                    showBlink && styles.success
                  } ${showRedBlink && styles.failure}`}
                  tabIndex="2"
                  maxLength="1"
                  onChange={(e) => handleOtpChange(e, otp2)}
                  onKeyDown={(e) => handleBackspace(e, otp2)}
                />
                <input
                  name="otp3"
                  type="number"
                  autoComplete="off"
                  ref={otp3}
                  className={`${styles.otpInput} ${
                    showBlink && styles.success
                  } ${showRedBlink && styles.failure}`}
                  tabIndex="3"
                  maxLength="1"
                  onChange={(e) => handleOtpChange(e, otp3)}
                  onKeyDown={(e) => handleBackspace(e, otp3)}
                />
                <input
                  name="otp4"
                  type="number"
                  autoComplete="off"
                  ref={otp4}
                  className={`${styles.otpInput} ${
                    showBlink && styles.success
                  } ${showRedBlink && styles.failure}`}
                  tabIndex="4"
                  maxLength="1"
                  onChange={(e) => handleOtpChange(e, otp4)}
                  onKeyDown={(e) => handleBackspace(e, otp4)}
                />
              </div>
              {timing > 0 && <div className={styles.timing}>0:{timing}</div>}
              <div className={styles.sent}>Didn’t get code yet ?</div>
              {activeResend ? (
                <div className={styles.resend} onClick={handleResendOtp}>
                  Resend OTP{" "}
                </div>
              ) : (
                <div className={styles.resend}>Resend OTP </div>
              )}
              <Button
                value="PROCEED"
                style={{
                  height: "48px",
                  width: "260px",
                  marginTop: "40px",
                  borderRadius: "20px",
                }}
                type="submit"
              ></Button>
            </form>
          </div>
        </div>

        {showVerified && (
          <div
            className={`${styles.overlay} container-fluid`}
            onClick={(e) => {
              e.stopPropagation();
              setShowVerified(false);
              setShowLogin(null);
            }}
          >
            {" "}
            <motion.div
              initial={{ translateY: "100%" }}
              animate={{ translateY: "0%" }}
              transition={{ duration: 0.4 }}
              className={styles.modalDialog}
              style={{
                position: "absolute",
                width: "100%",
                height: "60%",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <center className={styles.main}>
                <motion.img
                  animate={{ scale: [1, 1.1, 1] }}
                  transition={{ duration: 2, repeat: 50 }}
                  src={greenTick}
                  className={styles.greentick}
                />
                <h5>Verified</h5>
                <p>Your mobile number was successfully verified</p>
              </center>
            </motion.div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default OtpVerify;
