import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session"; // defaults to localStorage for web
import cartReducer from "./cartSlice";
import foodReducer from "./foodSlice";
import foodCategoryReducer from "./foodCategorySlice";
import scrollReducer from "./scrollSlice";
import userReducer from "./userSlice";
import otpReducer from "./otpSlice";
import ordersReducer from "./ordersSlice";
import searchReducer from "./searchSlice";
import orderHistoryReducer from "./orderHistorySlice";
import { combineReducers } from "@reduxjs/toolkit";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  cart: cartReducer,
  orders: ordersReducer,
  orderHistory: orderHistoryReducer,
  foods: foodReducer,
  foodCategory: foodCategoryReducer,
  scroll: scrollReducer,
  user: userReducer,
  otp: otpReducer,
  search: searchReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
