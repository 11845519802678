import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "black";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 1,

    // alignItems: "center",
    height: "auto",
    // fontStyle: "bold",
  },
  description: {
    width: "60%",
    paddingTop: 5,
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    width: "10%",
    paddingTop: 5,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  rate: {
    width: "15%",
    paddingTop: 5,
    borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: "center",
    // paddingRight: 8,
    fontFamily: "Inter",
  },
  amount: {
    width: "30%",
    paddingTop: 5,
    textAlign: "center",
    paddingRight: 8,
    fontFamily: "Inter",
  },
});

const InvoiceTableRow = ({ items }) => {
  const rows = items?.map((item, index) => (
    <View style={styles.row} key={index}>
      <Text style={styles.description}>
        {item.name}
        {/* {item.addOns && item.addOns.length ? "(" : ""} */}
        {/* {`${
          item?.addOns && item.addOns.length
            ? item.addOns.reduce(
                (result, value, index) =>
                  result + (index === 0 ? "" : ", ") + value.name,
                ""
              )
            : ""
        }`}
        {item.addOns && item.addOns.length ? ")" : ""} */}
      </Text>
      <Text style={styles.qty}>{item.quantity}</Text>
      {/* <Text style={styles.rate}></Text> */}
      <Text style={styles.amount}>
        {`₹${(item.total * item.quantity).toFixed(2)}`}
      </Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
