import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "black";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "black",
    backgroundColor: "white",
    borderBottomWidth: 1,
    height: "24",
    textAlign: "center",
    fontStyle: "bold",
    fontFamily: "Helvetica-Bold",
    flexGrow: 1,
  },
  description: {
    width: "60%",
    paddingTop: 5,
    height: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    textTransform: "uppercase",
  },
  qty: {
    width: "10%",
    paddingTop: 5,
    height: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    textTransform: "uppercase",
  },
  rate: {
    width: "15%",
    paddingTop: 5,
    height: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    textTransform: "uppercase",
  },
  amount: {
    width: "30%",
    paddingTop: 5,
    height: "100%",
    textAlign: "center",
    textTransform: "uppercase",
  },
});

const InvoiceTableHeader = () => (
  <>
    <View style={styles.container}>
      <Text style={styles.description}>Item Description</Text>
      <Text style={styles.qty}>Qty</Text>
      {/* <Text style={styles.rate}>Price</Text> */}
      <Text style={styles.amount}>Total</Text>
    </View>
  </>
);

export default InvoiceTableHeader;
