import React from "react";
import { Modal } from "react-bootstrap";

const ImageModal = ({ imageUrl, showModal, closeModal }) => {
  return (
    <Modal show={showModal} onHide={closeModal} centered>
      <Modal.Body>
        <img src={imageUrl} alt="" className="img-fluid" />
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
