import { createSlice } from "@reduxjs/toolkit";

const orderslice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
  },
  reducers: {
    addOrder: (state, action) => {
      state.orders.append(action.payload);
    },
    removeOrder: (state, action) => {
      state.orders.splice(action.payload, 1);
    },
  },
});

export const { addOrder, removeOrder } = orderslice.actions;
export default orderslice.reducer;
