import { createSlice } from "@reduxjs/toolkit";
const orderHistorySlice = createSlice({
  name: "ordersHistory",
  initialState: {
    ordersHistory: [],
  },
  reducers: {
    addOrderHistory: (state, action) => {
      state.ordersHistory.push(action.payload);
    },
  },
});

export const { addOrderHistory } = orderHistorySlice.actions;
export default orderHistorySlice.reducer;
