import styles from "../styles/SplashScreen.module.css";
import applogo from "../assets/new.jpg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRestaurantById } from "../services/restaurant.js";
import useToast from "../utils/toast/useToast.js";
import Cookies from "js-cookie";
const SplashScreen = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const restaurantId = params.get("restaurantId");
  const tableNumber = params.get("tableNumber");
  const tableId = params.get("tableId");
  const group = params.get("group");
  const orderType = params.get("orderType");
  // const [resDetails, setResDetails] = useState({});
  const orderDetails = { group: group, orderType: orderType };
  localStorage.setItem("orderDetails", JSON.stringify(orderDetails));

  useEffect(() => {
    async function fetch() {
      try {
        if (restaurantId && tableNumber && tableId) {
          const res = await getRestaurantById(restaurantId);
          if (!res) {
            toast.showErrorToast("No restaurant found");
            navigate("/qr", { replace: true });
            return;
          } else {
            if (
              res?.data?.qrCodes?.filter((qr) => qr?._id === tableId)?.length >
              0
            ) {
              localStorage.setItem("restaurantId", restaurantId);
              localStorage.setItem("tableNumber", tableNumber);
              localStorage.setItem("tableId", tableId);
              setTimeout(() => {
                navigate("/home", { replace: true });
              }, 2500);
              Cookies.remove("token");
            } else {
              toast.showErrorToast("Link expired.");
              navigate("/qr", { replace: true });
            }
            // console.log(res);
          }
        } else {
          setTimeout(() => {
            navigate("/qr", { replace: true });
          }, 2500);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetch();
  }, []);
  return (
    <div className={styles.outer}>
      <img src={applogo} alt="" className={styles.inner} />
    </div>
  );
};

export default SplashScreen;
