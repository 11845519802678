import React from "react";
import "../../styles/index.css";
import { motion } from "framer-motion";
import CommanLoader from "./common/Loader/CommanLoader";
export const Button = ({
  value,
  backgroundColor,
  style,
  showLoader = false,
  type,
  disabled = false,
  onClick = () => {},
}) => {
  const buttonType = type || "button";

  return (
    <motion.button
      disabled={disabled}
      type={buttonType}
      className="button"
      onClick={onClick}
      style={{ backgroundColor: disabled ? "gray" : backgroundColor, ...style }}
      whileTap={{ scale: disabled ? 1 : 0.9 }}
    >
      {value ? (!showLoader ? value : null) : "Click"}
      {showLoader ? (
        <CommanLoader
          color={"white"}
          size={"30px"}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      ) : null}
    </motion.button>
  );
};
