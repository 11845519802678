import React from "react";
import Loader from "react-js-loader";
const CommanLoader = ({ color, size, style }) => {
  return (
    <>
      <div className={"item"} style={style}>
        <Loader
          type="spinner-circle"
          color={"black"}
          bgColor={color}
          size={size}
        />
      </div>
    </>
  );
};

export default CommanLoader;
