import React from "react";
import { GrFormPrevious } from "react-icons/gr";
const LeftButtn = ({ previous }) => {
  return (
    <div
      className="position-absolute"
      style={{
        top: "45%",
        left: "7px",
        backgroundColor: "var(--fourth-color)",
        // border: "1px solid #7a1b1b",
        borderRadius: "50%",
      }}
      onClick={(e) => {
        e.stopPropagation();
        previous();
      }}
    >
      <GrFormPrevious size={25} color="white" />
    </div>
  );
};

export default LeftButtn;
