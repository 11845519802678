// CustomSlider.js
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LeftButtn from "./LeftButtn";
import RightButton from "./RightButton";

const CustomSlider = ({ children, settings, ordersLength }) => {
  let sliderRef = useRef(null);
  console.log(sliderRef);

  const next = () => {
    console.log(sliderRef.innerSlider.state);
    sliderRef.slickNext();
  };

  const previous = () => {
    console.log(sliderRef);
    sliderRef.slickPrev();
  };

  return (
    <div className="position-relative" style={{ maxHeight: "162px" }}>
      <Slider
        {...settings}
        ref={(slider) => {
          sliderRef = slider;
        }}
      >
        {children}
      </Slider>
      <div className="position-absolute top-50 start-50 translate-middle justify-content-between w-100">
        {ordersLength > 1 ? (
          <>
            {" "}
            <LeftButtn previous={previous} />
            <RightButton next={next} />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CustomSlider;

//   {index !== 0 && (
// <div
//   className="position-absolute"
//   style={{
//     top: "45%",
//     left: "-5px",
//     border: "1px solid #7a1b1b",
//     borderRadius: "50%",
//   }}
//   onClick={(e) => {
//     e.stopPropagation();
//     previous();
//   }}
// >
//   <GrFormPrevious size={25} />
// </div>
//   )}
//   {allOrders.length - 1 !== index && (
//     <div
//       className="position-absolute"
//       style={{
//         top: "45%",
//         right: "-11px",
//         border: "1px solid #7a1b1b",
//         borderRadius: "50%",
//       }}
//       onClick={(e) => {
//         e.stopPropagation();
//         next();
//       }}
//     >
//       <GrFormNext size={25} />
//     </div>
//   )}
// </div>;
