// Create a new file called searchSlice.js
// searchSlice.js

import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    searchTerm: "",
    showSearchBar: false, // Set initial state for searchTerm
  },
  reducers: {
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },

    setShowSearchBar(state, action) {
      state.showSearchBar = action.payload;
    },
  },
});

export const { setSearchTerm, setShowSearchBar } = searchSlice.actions;

export default searchSlice.reducer;
