import { useEffect, useState } from "react";
import styles from "./CounterButton.module.css";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../../store/cartSlice";


const CounterButton = ({
  setshowCustomize,
  setShowConfirmOrder,
  item,
  setShowRepeatPopup,
  setNewItem,
  setSameItem,
}) => {
  const dispatch = useDispatch();

  const itemsInCart = useSelector((state) => state.cart.items);
  const itemInCart = itemsInCart.find((cartItem) => cartItem._id === item._id);
  const [showCounter, setShowCounter] = useState(
    itemInCart ? itemInCart.quantity : 0
  );

  useEffect(() => {
    const itemInCart = itemsInCart.filter(
      (cartItem) => cartItem._id === item._id
    );
    const itemQuantity = itemInCart.reduce(
      (totalQuantity, item) => item.quantity + totalQuantity,
      0
    );

    setShowCounter(itemInCart ? itemQuantity : 0);
  }, [itemsInCart, item._id]);

  useEffect(() => {
    const totalQuantity = itemsInCart.reduce(
      (total, cartItem) => total + cartItem.quantity,
      0
    );
    if (totalQuantity === 0) {
      setshowCustomize(false);
      setShowConfirmOrder(false);
    }
  }, [itemsInCart, setshowCustomize, setShowConfirmOrder]);

  const handleEventAdd = () => {
    // item.category_id === foodCategorySlice[0]._id &&
    setNewItem(false);
    setShowRepeatPopup(true);
    // dispatch(addItem({ ...item, quantity: 1 }));
  };

  const handleEventSub = () => {
    setSameItem(false);
    setShowRepeatPopup(false);
    // If item has instructions, find item in cart matching _id, instructions, and quantityLevel
    let itemToRemove = item;

    dispatch(removeItem({ ...itemToRemove }));
  };

  return (
    // <div className={styles.counter}>
    //   <button onClick={handleEventSub}>-</button>
    //   <motion.span
    //     initial={{ y: 10, scale: 0, opacity: 0 }}
    //     animate={{ y: 0, scale: 1, opacity: 1 }}
    //     transition={{ duration: 0.3 }}
    //     key={showCounter}
    //   >
    //     {showCounter}
    //   </motion.span>
    //   <button onClick={handleEventAdd}>+</button>
    // </div>

    <div className={styles.counter}>
      <span onClick={handleEventSub}>-</span>
      <span className={styles.inputQuantity}>{showCounter}</span>
      {/* <input
      maxLength={3}
      type="number"
      value={counter}
      className={styles.inputQuantity}
      onChange={(e) => handleInputQuantity(e)}
      onBlur={(e) => {
        handleBlurInput(e);
      }}
      min={1}
    /> */}
      <span onClick={handleEventAdd}>+</span>
    </div>
  );
};

export default CounterButton;
